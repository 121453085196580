body {
  margin: 0;
  font-family: "Poppins", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: "Poppins-Light";
  font-display: swap;
  src: local("Poppins-Light"), url(./Fonts/Poppins-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-Medium";
  font-display: swap;

  src: local("Poppins-Medium"),
    url(./Fonts/Poppins-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-Thin";
  font-display: swap;

  src: local("Poppins-Thin"), url(./Fonts/Poppins-Thin.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-ExtraLight";
  font-display: swap;

  src: local("Poppins-ExtraLight"),
    url(./Fonts/Poppins-ExtraLight.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-ExtraBold";
  font-display: swap;

  src: local("Poppins-ExtraBold"),
    url(./Fonts/Poppins-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  font-display: swap;

  src: local("Poppins-Bold"), url(./Fonts/Poppins-Bold.ttf) format("truetype");
}
