/* Hide scrollbar for Chrome, Safari and Opera */
.example::-webkit-scrollbar {
  display: none;
}

.sourceDocContainer {
  background-color: #ffffff;
  margin: auto 0;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  /* top: 13vh;
  right: 1.5vw;
  border-radius: 20px; */
  z-index: 1100;
}

.text-editor-container {
  overflow: auto;
  position: relative;
  height: 55vh;
  max-width: 40vw;
  word-break: break-all;
}

.openSD {
  opacity: 1;
  transition-duration: 0.2s;
}

.hiddenSD {
  opacity: 0;
  transition-duration: 0.2s;
  pointer-events: none;
}
.SDMaximizeWrapper {
  position: fixed;
  top: 1.5%;
  left: 3.2%;
  height: 15px;
  width: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  cursor: pointer;
  border: 1px solid #ffaea6;
  border-radius: 25px;
}

.SDMaximize {
  position: relative;
  height: 50%;
  width: 28%;
  border: 1px solid #ffaea6;
  border-radius: 2px;
  z-index: 9999;
}
.SDMaximizeWrapper:hover .SDMaximize {
  background-color: white;
  height: 60%;
}

.SDMaximizeWrapper:hover {
  background-color: #ffaea6;
}

.SDMinimizeWrapper {
  position: relative;
  top: 1.5%;
  left: calc(3.2% + 38px);
  height: 15px;
  width: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  cursor: pointer;
  border: 1px solid #ffaea6;
  border-radius: 25px;
}

.SDMinimize {
  position: relative;
  height: 1px;
  width: 40%;
  background-color: #ffaea6;
  border-radius: 6px;
  z-index: 9999;
}
.SDMinimizeWrapper:hover .SDMinimize {
  background-color: white;
  height: 3px;
}

.SDMinimizeWrapper:hover {
  background-color: #ffaea6;
}

.search-bar img {
  position: absolute;
}

.search-bar {
  width: 100%;
  margin-bottom: 10px;
}

.search-icon {
  padding: 10px;
  width: 15px;
  text-align: center;
}

.search-input {
  width: -webkit-fill-available;
  padding: 10px;
  padding-left: 33px;
  border-radius: 1vw;
  color: black;
}

.search-input::placeholder {
  color: grey;
}

.containerNoScrollBar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.containerNoScrollBar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
