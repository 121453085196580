.node-label {
  margin: 3px;
}

.default {
  border-color: #ffaea6;
  color: grey;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  border-radius: 50%;
  background-color: none;
  border: 1px solid #6e6e6e;
  color: #6e6e6e;
  padding: 0.25em;

  /* Center the text contents */
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.circle .node-label {
  /* Set the height to 0 and overflow to visible to not interfere with the square styles */
  overflow: visible;
  height: 0;

  /* Vertically center text since we set its height to 0 */
  margin-top: -1em;
}

.FileNode {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  border: 1px solid #adadad;
  border-radius: 20px;
  /* background-color: #ffffff; */
  color: grey;
  /* transition: ease-in-out 0.2s; */
}

.FileNode:hover {
  background-color: #f2f2f2;
}

.DashedShape {
  /* display: flex;
  justify-content: center; */
  /* align-items: center; */
  border: 1px dashed #ffaea6;
  border-radius: 5px;
  background-color: none;
}

.node-label.corner {
  position: relative;
  top: -30px;
  left: 7%;
  text-align: left;
  margin-left: 0px;
}
.node-button.corner {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10000000;
}

.node-button.corner:hover {
  cursor: pointer;
  filter: brightness(90%);
}

.menu-item {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.menu-text {
  color: rgb(80, 80, 80);
}
.shortcut-key {
  color: rgb(155, 155, 155);
}

.CircleShape {
  border-radius: 50%;
  background-color: rgba(255, 255, 255);
  border: 1px solid #adadad;
}

.ShadowBoxShape {
  border-radius: 5%;
  background-color: rgba(255, 255, 255, 0.6);
  box-shadow: -2px 4px 5px #dadadacd;
}

.react-flow__node {
  border-width: 2px;
}

.react-flow__node:hover {
  cursor: default;
}

.highlightedNode {
  color: grey;
  font-weight: bold;
  background-color: #fafafa;
  border: 1px solid #ff8b8b;
  box-shadow: -1px 3px 2px #dadada;
}

.highlightedWrapper {
  transition: ease-in-out 0.1s;
  border: 2px dashed #ff8b8b;
}

.Text {
  border-radius: 5px;
  background-color: none;
  padding: 10px 10px 10px 10px;
}

.selectedText {
  border: 1px solid #ff8b8b;
}

.resizeHandle {
  background-color: white;
  border: 1px solid #ff8b8b;
  box-shadow: -1px 3px 2px #dadada;
}

.resizeHandle:hover {
  background-color: #dadada;
  border: 1px solid #dadada;
}

.resizeHandle:active {
  background-color: #c5c5c5;
  border: 1px solid #c5c5c5;
}

.react-flow__edge-path {
  background-color: blue;
  padding: 5;
}
