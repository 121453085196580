.ToolBarButton:hover .Rect {
  stroke: #ffaea6;
}

.ToolBarButton:hover .options {
  fill: #ffaea6;
}

.ToolBarButton:hover .TextIcon {
  fill: #ffaea6;
}

.ToolBarButton:hover path {
  fill: #ffaea6;
}

.PopupButton:hover rect {
  stroke: #ffaea6;
}

.PopupButton:hover .CircleShape {
  stroke: #ffaea6;
}

.ToolBarButton:hover .DashedShape {
  stroke: #ffaea6;
}

.ToolBarButton:hover .ShadowBoxShape,
.PopupButton:hover .ShadowBoxShape {
  stroke: #ffaea6;
  fill: #ffaea6;
}

.ShapesGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
}
