.App {
  background-color: rgb(247, 247, 247);
  --ck-border-radius: 4px;
}
.canvasContainer {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgb(247, 247, 247);
  border: 1px darkgrey solid;
  margin: auto 0;
  z-index: -1;
}

.canvas {
  position: fixed;
  top: 10vh;
  height: 90vh;
  left: 0;
  width: 100vw;
  background-color: rgb(247, 247, 247);
  border: 1px darkgrey solid;
  margin: auto 0;
}

.navbar-button {
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: bold;
  cursor: pointer;
  min-height: 4vh;
  min-width: 4vh;
  transition: ease-in 0.1s;
}

.go-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
  transition: ease-in 0.1s;
  height: 35px;
  width: 45px;
}

.GoButtonWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid #ffaea6;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.go-button:hover .GoButtonWrapper {
  background-color: #ffaea6;
}

.go-button:hover p {
  color: white;
}

.public-repo-link {
  border-radius: 30px;
  padding-left: 20px;
  padding-right: 20px;
  outline: none;
  height: 35px;
  width: 350px;
  border: 1px solid #ffaea6;
  color: #ffaea6;
  background: transparent;
  appearance: none;
  cursor: text;
  background-color: white;
  text-align: center;
}
.public-repo-link:focus {
  border: 1.5px solid #ffaea6;
}

.public-repo-link:focus::placeholder {
  color: transparent;
}

.LandingPage {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #fdf7f6;
}

.welcomeMessage {
  font-size: 2.2vw;
  font-family: "Poppins-Thin";
  letter-spacing: 0.02em;
  line-height: 1.5em;
  text-align: left;
  padding-left: 5vw;
}

.CodeGram {
  color: #ffaea6;
  font-family: "Poppins-Bold";
  font-size: 3vw;
  letter-spacing: 0.02em;
  font-weight: 50;
  line-height: 1.05;
  z-index: 1;
}
.GitHubButtonWrapper {
  position: relative;
  height: 15%;
  padding-top: 5%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

html,
body {
  padding: 0;
  margin: 0;
}

.GitHubButtonWrapper:hover .GitButton {
  fill: #ffaea6;
  cursor: pointer;
}

.GitHubButtonWrapper:hover .LoginWithGitHub {
  fill: #ffffff;
  cursor: pointer;
}

.landingLogin:hover {
  color: #ff8b8b;
  cursor: pointer;
  font-weight: bold;
}

.SourceDocFile {
  position: relative;
  width: 90%;
  border-radius: 1vh;
  margin: 5px 0;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  display: flex;
  justify-content: space-between;
  max-height: 60px;
}
.CanvasFile {
  position: relative;
  width: 90%;
  border-bottom: 1px solid #d4d4d4;
  display: flex;
  justify-content: space-between;
  max-height: 60px;
  margin-left: 10%;
  margin-right: 10%;
}

.SourceDocFile:hover {
  box-shadow: rgba(0, 0, 0, 0.623) 1.95px 1.95px 2.6px;
  cursor: default;
}

.selectedFile {
  border: 1px solid #ff8b8b;
}

.canvasLinked {
  background-color: #f3f3f3;
  color: #626262;
}

.canvasUnlinked {
  background-color: white;
  color: black;
}

.canvasUnlinked:hover {
  background-color: rgb(252, 252, 252);
}

.unlinked {
  background-color: #eeeeee;
  color: #626262;
}

.linked {
  background-color: rgb(248, 248, 248);
  color: black;
}

.dashboard {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100vw;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  position: fixed;
  top: 0;
}

.toolbar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30vw;
  z-index: 1;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ease-in 0.1s;
  cursor: pointer;
}
.iconWrapper:hover {
  color: black;
}

html,
body {
  padding: 0;
  margin: 0;
}

.LogoDiv {
  position: relative;
  height: 20%;
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.LogoWrapper {
  position: relative;
  height: 80%;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.nodeCard {
  font-size: 10pt;
  border: 1px solid #626262;
  border-radius: 1vh;
  padding: 1%;
  margin: 5px;
}

.nodeCard:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  cursor: default;
}

.background {
  position: absolute;
  top: -100vh;
  left: -40vw;
  transform: rotate(0deg);
  height: 200vw;
  width: 150vw;
  z-index: 0;
}

a {
  text-decoration: none;
  color: #ff8b8b;
  font-weight: bold;
}

a:hover {
  text-decoration: underline;
}

pre {
  font-size: 12px;
}

.repoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PlusSign {
  position: fixed;
  z-index: 10000;
}

.PlusSign:hover {
  cursor: pointer;
}

.pathContainer {
  margin-bottom: 2%;
  padding: 1% 5%;
  background-color: rgb(241, 241, 241);
  border-radius: 1vw;
  max-height: 30px;
}

.pathContainer p {
  display: inline;
  margin: 0;
  color: rgba(0, 0, 0, 0.769);
  font-size: 14px;
}

.pathContainer p:hover {
  cursor: pointer;
  color: #ff8b8b;
}

.SaveButtonWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid #ffaea6;
  border-radius: 0.6vw;
}

.EditWikiButtonWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid #ffaea6;
  border-radius: 0.6vw;
  color: #ffaea6;
  z-index: 10;
}

.navbar-button:hover .EditWikiButtonWrapper {
  background-color: #ffaea6;
}

.navbar-button:hover .SaveButtonWrapper {
  background-color: #ffaea6;
}

.navbar-button:hover p {
  color: white;
  font-weight: 500;
}

.maxButton:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  cursor: pointer;
}

::placeholder {
  color: rgb(189, 189, 189);
}

.SourceDocButtonWrapper {
  position: fixed;
  top: 43vh;
  right: -1vw;
  height: 7vw;
  width: 7vw;
  cursor: pointer;
}

.SourceDocButtonWrapper:hover .SDBC {
  fill: #dba5a5;
}

.ck-editor__editable_inline {
  min-height: 20vh;
}
.emptyWikiButton {
  width: 100%;
  height: 30%;
  background-color: #f7f7f7;
  color: #7e7e7e;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2% 0;
}
.emptyWikiButton:hover {
  opacity: 0.9;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.LearnMoreButtonWrapper {
  position: relative;
  height: 15%;
  padding-top: 5%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.prNotif{
  font-size: 10pt;
  color: #797979;
  font-weight: bold;
}

.notifContainer{
  display: flex;
  justify-content: center;
  bottom: 0;
  margin:none
}

.prNotif:hover{
  color: #ff8b8b;
  cursor: pointer;
}

.LearnMoreButtonWrapper:hover .LearnMoreButton {
  fill: #ffaea6;
  cursor: pointer;
}

.LearnMoreButtonWrapper:hover .LearnMoreText {
  fill: #ffffff;
  cursor: pointer;
}
